export const environment = {
    production: false,
    baseURL: 'https://api-dev.returnmail.ai',
    azureAdB2c: {
        clientId: 'b7b74d88-f2d1-4761-8311-e2b553b687e1',
        tenantId: 'f1f53724-a321-40c5-a209-1c65531dbda6',
        tenantName: 'returnmaildev',
        redirectUri: 'https://app-dev.returnmail.ai',
        signIn: 'B2C_1_SignIn',
        signUp: 'B2C_1_SignUp',
        forgotPassword: 'B2C_1_PasswordReset',
        imagesPath: 'https://returnmaildevst001eus.blob.core.windows.net/uploaded-images/',
    },
};
