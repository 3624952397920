import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { OrganizationDetails } from '../../models/organization-details.interface';
import { AccountInfo } from '@azure/msal-browser';
import { OrganizationInfo } from '../../models/organization-info.interface';
import { environment } from '../../environments/environment';
import { InviteUserModel } from '../../models/add-user.model';
import { UserModel } from '../../models/user-model.interface';
import { UploadedImage } from '../../models/uploaded-image.model';
import { Customer, CustomerResponse } from '../../models/customer.model';

@Injectable({
    providedIn: 'root',
})
export class DbService {
    public organizationData$ = new BehaviorSubject<OrganizationInfo | null>(null);
    private baseUrl = environment.baseURL;
    constructor(private http: HttpClient) {}

    public registerOrganization(account: AccountInfo): Observable<OrganizationDetails | null> {
        console.log('ACCOUNT CALLBACK', account);
        if (!account.idTokenClaims && !account) {
            return of(null);
        }

        // INVITE USER
        const organizationDetails: OrganizationDetails = this.mapClaimsToOrganizationDetails(account.idTokenClaims || account);
        if (!account.idTokenClaims && account) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            organizationDetails.userIdentifier = (account as any).oid;
        }

        const endpoint = '/Registration/Callback';

        return this.http.post<OrganizationDetails>(this.baseUrl + endpoint, organizationDetails);
    }

    public getOrganizationDetails(): Observable<OrganizationInfo | null> {
        const endpoint = '/OrganizationDetails';
        const fullURL = `${this.baseUrl}${endpoint}`;
        return this.http.get<OrganizationInfo | null>(fullURL);
    }

    public saveOrganizationDetails(organization: OrganizationInfo): Observable<OrganizationInfo | null> {
        const endpoint = `/OrganizationDetails/${organization.id}`;
        const fullURL = this.baseUrl + endpoint;
        return this.http.put<OrganizationInfo | null>(fullURL, organization);
    }

    public sendUserInvite(userData: InviteUserModel): Observable<void> {
        const endpoint = `/Invite`;
        const fullURL = this.baseUrl + endpoint;
        return this.http.post<void>(fullURL, userData);
    }

    public editUser(userData: UserModel): Observable<void> {
        const endpoint = `/User/${userData.id}`;
        const fullURL = this.baseUrl + endpoint;
        return this.http.put<void>(fullURL, userData);
    }

    public activateUser(userId: number): Observable<void> {
        const endpoint = `/User/activate/${userId}`;
        const fullURL = this.baseUrl + endpoint;
        return this.http.post<void>(fullURL, {});
    }

    public deactivateUser(userId: number): Observable<void> {
        const endpoint = `/User/deactivate/${userId}`;
        const fullURL = this.baseUrl + endpoint;
        return this.http.delete<void>(fullURL);
    }

    public setAdminUser(userId: number, organizationId: number): Observable<void> {
        const endpoint = `/TransferAdminRole?toUserId=${userId}&forOrganizationId=${organizationId}`;
        const fullURL = this.baseUrl + endpoint;
        return this.http.post<void>(fullURL, {});
    }

    public uploadImages(images: FormData): Observable<void> {
        const endpoint = `/Image/Upload`;
        const fullURL = this.baseUrl + endpoint;
        return this.http.post<void>(fullURL, images);
    }

    public getImages(): Observable<UploadedImage[]> {
        const endpoint = `/Image/List`;
        const fullURL = this.baseUrl + endpoint;
        return this.http.get<UploadedImage[]>(fullURL);
    }

    public downloadImage(customerId: number): Observable<HttpResponse<Blob>> {
        const endpoint = `/Image/${customerId}`;
        const fullURL = this.baseUrl + endpoint;
        return this.http.get(fullURL, {
            responseType: 'blob',
            observe: 'response',
        });
    }

    public getUserByIdentifier(userIdentifier: string): Observable<UserModel[]> {
        const endpoint = `/Odata/Users?$filter=userIdentifier eq '${userIdentifier}'`;
        const fullURL = this.baseUrl + endpoint;
        return this.http.get<UserModel[]>(fullURL);
    }

    public getCustomers(pageNumber = 1, pageSize = 10, status = 'Scanned'): Observable<CustomerResponse> {
        const endpoint = `/Customer?customerStatus=${status}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
        const fullURL = this.baseUrl + endpoint;
        return this.http.get<CustomerResponse>(fullURL);
    }

    public updateCustomer(customer: Customer): Observable<Customer> {
        const endpoint = `/Customer/${customer.id}`;
        const fullURL = this.baseUrl + endpoint;
        return this.http.put<Customer>(fullURL, customer);
    }

    public deleteCustomerAddress(customer: Customer): Observable<Customer> {
        const endpoint = `/Customer/Address/${customer.id}`;
        const fullURL = this.baseUrl + endpoint;
        return this.http.delete<Customer>(fullURL);
    }

    public createCustomer(customer: Customer): Observable<Customer> {
        const endpoint = `/Customer/${customer.id}`;
        const fullURL = this.baseUrl + endpoint;
        return this.http.post<Customer>(fullURL, customer);
    }

    public updateUser(user: UserModel): Observable<void> {
        const endpoint = `/User/${user.id}`;
        const fullURL = this.baseUrl + endpoint;
        return this.http.put<void>(fullURL, user);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private mapClaimsToOrganizationDetails(claims: any): OrganizationDetails {
        return {
            fullName: claims.extension_FullName || '',
            phoneNumber: claims.extension_Phonenumber.toString() || '',
            officialEmail: claims?.emails?.length > 0 ? claims?.emails[0] : '',
            jobTitle: claims.jobTitle || '',
            organizationName: claims.extension_OrganizationName || '',
            companyName: claims.extension_CompanyName || '',
            shortName: claims.extension_ShortName || '',
            streetAddress: claims.streetAddress || '',
            city: claims.city || '',
            stateOrProvince: claims.state || '',
            postalCode: claims.postalCode || '',
            website: claims.extension_Website || '',
        };
    }
}
